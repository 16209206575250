
  /* ----- modal ------*/
  .OverlayContainer {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    max-width: 100vw;
    max-height: 100vh;
    z-index: 2000;
    /* opacity: 0.95; */
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .OverlayMainContainer {
    max-width: 70vw;
    min-width: 35vw;
    background-color: #fff;
    border-radius: 5px;
   
    /* flex-direction: column; */
    z-index: 3000;
    transition: all 0.4s;
    max-height: 80vh;
    overflow-y: auto;
  }
 
  .ModalText {
    padding-left: 30px;
    padding-right: 25px;
    padding-bottom: 5px;
    padding-top: 5px;
  }

  .OkButton {
    float: right;
    margin-bottom: 20px;
  }

  .CloseButton {
    float: right;
    margin-top: 15px;
    margin-right: 30px;
  }

  .ModalButton {
    float: right;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .hr-line {
    margin: 0px;
  }

  .ModalHeader {
    padding-left: 25px;
    
  }

  .row {
    display: flex;
    width: 100%
  }
  .row::after {
    /* display: block;*/
    clear: both;
    content: "";
  }
  .col-1 {width: 8.33%};
  .col-2 {width: 16.66%;}
  .col-3 {width: 25%;}
  .col-4 {width: 33.33%;}
  .col-5 {width: 41.66%;}
  .col-6 {width: 50%;}
  .col-7 {width: 58.33%;}
  .col-8 {width: 66.66%;}
  .col-9 {width: 75%;}
  .col-10 {width: 83.33%;}
  .col-11 {width: 91.66%;}
  .col-12 {width: 100%;}
  /* end */
  @media screen and (min-width: 320px) and (max-width: 767px) {
    .LeftBar {
      display: none;
    }
    .OverlayMainContainer {
      width: 72vw;
    }
    
  
  }
  
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    .IntroContainer {
      height: 18.5rem;
      overflow: scroll;
    }
    .Introduction {
      margin-left: 1.5rem;
    }
    .ImageContainer {
      width: 40%;
      padding-left: 0px;
    }
  }
  