.benefit-application-member-remove{
    // font-size: 14px;
    color: #00607f;
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
}

.benefit-application-member-edit{
    // font-size: 14px;
    color: #00607f;
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
}

.benefit-application-member-slash{
    color: #00607f;
    text-decoration: underline;
    margin: 0;
}