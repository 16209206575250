.divA {
    margin: 5vh;
}

#row1{
    display:flex;
    flex-direction:row;
justify-content: space-around;
}

#column1{
    display:flex;
    flex-direction:column;
   
}


#column2{
    display:flex;
    flex-direction:column;
}