// Global layout styles, primarily responsible for sticking
// the footer to the bottom of the screen, and overriding
// some aspects of the U.S. Web Design System that can't
// be configured through a Sass variable.

//As per Nava's style guide, any html element styling overrides can go here
//DO NOT override any html elements unless it's an approved global styling change 
h1 {
    margin: .75em 0 0;
}

hr {
    margin: 1.5rem 0 0;
}

h3 {
    margin: 1.5rem 0 0;
    font-weight: 400;
}

.font-body-md {
    font-size: 1rem;
}

.l-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.l-main {
    flex-grow: 1;
    width: 100%;
}

.usa-form {
    // USWDS defaults their form width to be very narrow,
    // which doesn't work with our larger type styles
    max-width: units("mobile-lg");
}

.usa-error-message {
    min-height: 29px;
}

.margin-top-4 {
    margin-top: 2em !important;
}

.margin-top-neg-205 {
    margin-top: -1.25rem !important;
}

.inline-block {
    display: inline-block;
}

.usa {
    @include typeset(
    $theme-form-font-family,
    $theme-body-font-size,
    $theme-input-line-height
  );
}

.bold {
    font-weight: 700;
}

.usa-card__header-agree {
    @extend .usa-card__header;
    background-color: $theme-color-base-lighter;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.usa-card__container-agree {
    @extend .usa-card__container;
    margin-left: 0rem;
    margin-right: 0rem;
    border-radius: 0rem;
}

.usa-agree {
    @extend .usa;
    max-width: 40rem;
}

.agree-scroll-content {
    max-height: 400px;
    overflow: auto;
}

.container-phone {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-end;

    &-number {
        width: 40%;
        min-width: 180px;
    }

    &-ext {
        width: 20%;
        min-width: 60px;
    }

    &-type {
        width: 30%;
        min-width: 100px;
    }

    &-number-max-width {
        max-width: 230px;
    }
}

.usa-input-group-readonly {
    border-style: none
}

.float_right {
    float: right;
}

.margin-bottom-20{
    margin-bottom: 20px;
}

.margin-top-0{
    margin-top: 0px;
}

.usa-mandatory-label {
    color: #9a2125;
    margin-left: 3px;
}

.footer {
    position: relative;
    bottom: 0;
    padding-top: 10px;
    width: 100%;
    height: 2.5rem;
    background-color: #888888;;
    color: white;
    text-align: center;
}

.footer_text{
    margin-top: 10px;
    margin-bottom: 10px;
}

.header {
    overflow: hidden;
    /* background-color: #fff; */
    background: linear-gradient(90deg,#002633,#00607f 57.29%);
    background-image: linear-gradient(90deg, rgb(0, 38, 51), rgb(0, 96, 127) 57.29%);
    background-position-x: initial;
    background-position-y: initial;
    background-size: initial;
    background-attachment: initial;
    background-origin: initial;
    background-clip: initial;
    background-color: initial;
    height: 94px;
}

.header a {
    float: left;
    color: #f9f9f9;
    text-align: center;
    text-decoration: none;
    font-size: 22px;
    line-height: 1px;
    // border-radius: 4px;
    cursor: pointer;
    margin-top: 30px;
    margin-left: 20px;
}

.headerbottombar {
    width: 100%;
    height: 12px;
    background-color: #ffc843;
   
}
.subheading {
    height: 50px;
    border-radius: 1px;
    margin-left: 20px;
    margin-right: 20px;
}

.subheading-backbutton {
    margin-top: 11px;
    margin-left: 5px;
    margin-right: 5px;
}

.subheading-title {
    margin:5px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    color: #0f0f10;
    word-wrap: break-word;
    white-space: normal;
    font-size: 40px;
    font-weight: bold;
    max-height: 280px;
}

.landing-heading-title {
    
    font-family: 'Roboto', sans-serif;
    color: #0f0f10;
    word-wrap: break-word;
    white-space: normal;
    font-size: 40px;
    font-weight: bold;
    max-height: 280px;
}

.form-layout {
    min-height: 300px;
    margin-left: 20px;
    margin-right: 20px;
}

.from-fields-layout {
    margin: 20px auto;
}

.footer-text {
    padding: 25px 10px 25px 10px;
}

.home_page_css {
    margin: 30px !important;
}
.footer_fixed {
    position: fixed;
    bottom: 0;
    padding-top: 10px;
    width: 100%;
    height: 2.5rem;
    background-color: #888888;;
    color: white;
    text-align: center;
}