/*
--------------------------------------------------------------------------------
U.S. Web Design System - General settings overrides
--------------------------------------------------------------------------------
Read more about settings and USWDS color tokens in the documentation:
https://designsystem.digital.gov/design-tokens/color
--------------------------------------------------------------------------------
*/

/*
----------------------------------------
Theme palette colors
----------------------------------------
*/

$dhhs-primary: #00607f;
$dhhs-secondary: #ffc843;
$dhhs-light-gray: #949495;
$dhhs-dark-gray: #4d4d4f;
$dhhs-yellow: #ffc843;
$dhhs-red: #c1292e;

// Base colors
$theme-color-base-family: $dhhs-dark-gray;
$theme-color-base-lightest: #ededed;
$theme-color-base-lighter: #dbdbdc;
$theme-color-base-light: $dhhs-light-gray;
$theme-color-base: $dhhs-dark-gray;
$theme-color-base-dark: #3e3e3f;
$theme-color-base-darker: #2e2e2f;
$theme-color-base-darkest: #0f0f10;
$theme-color-base-ink: #0f0f10;

// Primary colors
$theme-color-primary-family: $dhhs-primary;
// $theme-color-primary-lightest: false;
$theme-color-primary-lighter: #ccdfe5;
$theme-color-primary-light: #66a0b2;
$theme-color-primary: $dhhs-primary;
$theme-color-primary-vivid: $theme-color-primary;
$theme-color-primary-dark: #004d66;
$theme-color-primary-darker: #002633;
// $theme-color-primary-darkest: false;

// Secondary colors
$theme-color-secondary-family: $dhhs-secondary;
// $theme-color-secondary-lightest: false;
$theme-color-secondary-lighter: #fff4d9;
$theme-color-secondary-light: #ffde8e;
$theme-color-secondary: $dhhs-secondary;
$theme-color-secondary-vivid: $theme-color-secondary;
$theme-color-secondary-dark: #cca036;
$theme-color-secondary-darker: #997828;
// $theme-color-secondary-darkest: false;

// Accent warm colors
// $theme-color-accent-warm-family: "orange";
// $theme-color-accent-warm-lightest: false;
// $theme-color-accent-warm-lighter: "orange-10";
// $theme-color-accent-warm-light: "orange-20v";
// $theme-color-accent-warm: "orange-30v";
// $theme-color-accent-warm-dark: "orange-50v";
// $theme-color-accent-warm-darker: "orange-60";
// $theme-color-accent-warm-darkest: false;

// Accent cool colors
// $theme-color-accent-cool-family: "blue-cool" ;
// $theme-color-accent-cool-lightest: false;
// $theme-color-accent-cool-lighter: "blue-cool-5v";
// $theme-color-accent-cool-light: "blue-cool-20v";
// $theme-color-accent-cool: "cyan-30v";
// $theme-color-accent-cool-dark: "blue-cool-40v";
// $theme-color-accent-cool-darker: "blue-cool-60v";
// $theme-color-accent-cool-darkest: false;

/*
----------------------------------------
State palette colors
----------------------------------------
*/

// Enable support for the state colors in all color-related utility classes (i.e .bg-success)
$global-color-palettes: ("palette-color-default", "palette-color-state");

// Error colors
$theme-color-error-family: $dhhs-red;
$theme-color-error-lighter: #f3d4d5;
$theme-color-error-light: #da7f82;
$theme-color-error: $dhhs-red;
$theme-color-error-dark: #9a2125;
$theme-color-error-darker: #74191c;

// Warning colors
$theme-color-warning-family: $dhhs-yellow;
$theme-color-warning-lighter: #fff4d9;
$theme-color-warning-light: #ffde8e;
$theme-color-warning: $dhhs-yellow;
$theme-color-warning-dark: $theme-color-warning;
$theme-color-warning-darker: $theme-color-warning;

// Success colors
$theme-color-success-family: "green-cool";
$theme-color-success-lighter: "green-cool-5";
$theme-color-success-light: "green-cool-20v";
$theme-color-success: "green-cool-40v";
$theme-color-success-dark: "green-cool-50";
$theme-color-success-darker: "green-cool-60";

// // Info colors
$theme-color-info-family: $dhhs-primary;
$theme-color-info-lighter: $theme-color-primary-lighter;
$theme-color-info-light: $theme-color-primary-light;
$theme-color-info: $theme-color-primary;
$theme-color-info-dark: $theme-color-primary-dark;
$theme-color-info-darker: $theme-color-primary-darker;

// Disabled colors
// $theme-color-disabled-family: "gray";
// $theme-color-disabled-light: "gray-10";
// $theme-color-disabled: "gray-20";
// $theme-color-disabled-dark: "gray-30";

// Emergency colors
// $theme-color-emergency: "red-warm-60v";
// $theme-color-emergency-dark: "red-warm-80";

/*
----------------------------------------
General colors
----------------------------------------
*/

// Body
$theme-body-background-color: "white";

// Text
$theme-text-color: "ink";
$theme-text-reverse-color: "white";

// Links
$theme-link-color: "primary";
$theme-link-visited-color: "violet-70v";
$theme-link-hover-color: "primary-dark";
$theme-link-active-color: "primary-darker";
$theme-link-reverse-color: "base-lighter";
$theme-link-reverse-hover-color: "base-lightest";
$theme-link-reverse-active-color: "white";
