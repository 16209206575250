body {
    margin: 0;
    /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
        "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif; */
    font-family: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.text-align-right {
    text-align: right;
    
}

.switch-toggle-box {
    vertical-align: middle;
    margin-right: 5px;
    margin-bottom: 10px;
}
.switch-toggle-text {
    font-size: 20px;
}

.custom_loder {
    margin-top: 150px;
    margin-left: 150px;
}
