@mixin mobile {
    @media (max-width: 479px) { @content }
  }

  .benefit-discovery-wrapper{
      padding-top: 20px;
      color: #4D4D4F;
  }

// .benefit-discovery-active-button-wrapper{
//     @include mobile {
//         display: grid;
//         grid-template-columns: 2.5fr 5.8fr;
//         column-gap: 24px;
//     }
//     margin-bottom: 20px;
// }

.benefit-discovery-bold{
    font-weight: 700;
    line-height: 1.5; 
    margin-bottom: 5px;
}

.benefit-discovery-bold-l{
    font-weight: 700;
    line-height: 1.5; 
    font-size: 20px;
    margin: 0;
}

.benefit-discovery-text{
    line-height: 1.7; 
}

.benefit-discovery-subtitle{
    margin-top: 0;  
}

.benefit-discovery-margin-bottom{
    margin-bottom: 20px;
}

.benefit-discovery-margin-bottom-l{
    margin-bottom: 40px;
}

.benefit-discovery-negative-margin-top{
    margin-top: -25px;
}

.benefit-discovery-button{
    padding: 1.25rem 1.25rem;
    width: 100%;
}

.benefit-discovery-welcome-button-margin{
    margin-top: 120px;
}

.benefit-discovery-remove-wrapper{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0;
}

.benefit-discovery-remove{
    font-size: 14px;
    color: rgb(89, 89, 255);
    text-decoration: underline;
    margin: 0;
    cursor: pointer;
}

.benefit-discovery-cancel-questionnaire{
    color: #00607F;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 20px;
    cursor: pointer;
}

.benefit-discovery-result{
    margin-top:20px !important;
}

.benefit-discovery-community-link{
    color: #00607F;
    margin-bottom: 20px;
    cursor: pointer;
    text-decoration: underline;
    font-size: 16px;
}

.benefit-discovery-result-category{
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
}